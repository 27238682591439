import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Input, Button } from '@nazka/nazka.mapframe.components';

import queryClient from '@utils/queries/QueryClient';

import { setShowActiveDrawingLayer, setPopup } from '@library/MaplibreMap/maplibre-map.redux.actions';
import { setIsCreatingNewArea, setSelectedAreaId } from '@App/components/NewDamageLayer/new-damage-layer.redux.actions';
import { defaultInfiltrationParameters } from '@App/components/DamageParameter/DamageParameterTable/damage-parameter-table.constants';
import { removeAllFeatures } from '../Measurement/measurement.utils';

import './measurement-popup.styl';

function MeasurementPopup(props) {
  const { map, feature } = props;
  const [description, setDescription] = useState();

  const isRangeExceeded = useSelector(state => state.maplibreMap.exceededRange);
  // const noBuildingIntersecting = useSelector(state => state.maplibreMap.noBuildingIntersecting);
  const userSelectedMode = useSelector(state => state.maplibreMap.userSelectedMode);

  const dispatch = useDispatch();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async () => {
      const payload = {
        areaName: description || 'New area',
        userSelectedMode,
        infiltrationParameter: defaultInfiltrationParameters,
        feature,
      };

      const { data } = await axios.post('/api/user/areas', payload);
      return data;
    },
    onSuccess: (createdArea) => {
      dispatch(setPopup(null));
      dispatch(setShowActiveDrawingLayer(true));
      toast.success('User area created');

      const previousAreas = queryClient.getQueryData(['user', 'areas']) || [];
      queryClient.setQueryData(['user', 'areas'], [createdArea, ...previousAreas]);
      dispatch(setSelectedAreaId(createdArea.id));
      dispatch(setIsCreatingNewArea(false));
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault()
    mutate()
  }

  if (isError) {
    return (
      <div className="measurement-popup">
        <span className="measurement-popup__error__text">
          Something went wrong creating your damage calculation
          <Button
            className="measurement-popup__buttons__button warning__button"
            onClick={() => {
              removeAllFeatures(map);
              dispatch(setIsCreatingNewArea(false));
            }}
            hoverColor="#0367a5dd"
            css={{
              height: '30px',
              fontSize: '14px',
              lineHeight: 0,
              borderRadius: '3px',
            }}
          >
            Reset
          </Button>
        </span>
      </div>
    );
  }

  // if (noBuildingIntersecting) {
  //   return (
  //     <div className="measurement-popup">
  //       <span className="measurement-popup-warning">
  //         Your selected area does not contain any buildings. Please draw a new area.
  //       </span>
  //       <Button
  //         className="measurement-popup__buttons__button warning__button"
  //         onClick={() => {
  //           removeAllFeatures(map);
  //           dispatch(setIsCreatingNewArea(false));
  //         }}
  //         hoverColor="#0367a5dd"
  //         css={{
  //           height: '30px',
  //           fontSize: '14px',
  //           lineHeight: 0,
  //           borderRadius: '3px',
  //         }}
  //       >
  //         Reset
  //       </Button>
  //     </div>
  //   )
  // }

  if (isRangeExceeded) {
    return (
      <div className="measurement-popup">
        <span className="measurement-popup-warning">
          Your selected area exceeds the size limit of 1 km². Please draw a new area.
        </span>
        <Button
          className="measurement-popup__buttons__button warning__button"
          onClick={() => {
            removeAllFeatures(map);
            dispatch(setIsCreatingNewArea(false));
          }}
          hoverColor="#0367a5dd"
          css={{
            height: '30px',
            fontSize: '14px',
            lineHeight: 0,
            borderRadius: '3px',
          }}
        >
          Reset
        </Button>
      </div>
    )
  }

  return (
    <div className="measurement-popup">
      <form onSubmit={handleSubmit}>
        <Input
          name="description"
          rootElProps={{
            placeholder: 'Area name...',
          }}
          onInputChange={e => setDescription(e.target.value)}
          css={{
            border: '1px solid #ddd',
            borderRadius: '4px',
          }}
        />
        <div className="measurement-popup__buttons">
          <Button
            isLoading={isPending}
            hoverColor="#0367a5dd"
            css={{
              height: '30px',
              fontSize: '14px',
              lineHeight: 0,
              borderRadius: '3px',
            }}
            type="submit"
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              removeAllFeatures(map);
              dispatch(setIsCreatingNewArea(false));
            }}
            layout="outlined"
            hoverColor="#0367a5dd"
            css={{
              height: '30px',
              fontSize: '14px',
              lineHeight: 0,
              borderRadius: '3px',
              border: '1px solid #0367a554',
            }}
            disabled={isPending}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}

export default MeasurementPopup;