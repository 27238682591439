const rainfallTerm = [
  { value: 'present', label: 'present' },
  { value: '2040', label: '2040' },
  { value: '2070', label: '2070' },
  { value: '2100', label: '2100' },
]

const rcpScenario = [
  { value: 'No scenario', label: 'No scenario' },
  { value: 'RCP2.6', label: 'RCP2.6' },
  { value: 'RCP4.5', label: 'RCP4.5' },
  { value: 'RCP8.5', label: 'RCP8.5' },
]

const returnPeriod = [
  { value: 2, label: '2' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

const flashFloodData = [
  {
    scenario: 'No scenario',
    term: 'present',
    periods: {
      2: 16, 5: 21, 10: 24, 25: 29, 50: 33, 100: 37, 
    }, 
  },
  {
    scenario: 'RCP2.6',
    term: '2040',
    periods: {
      2: 17, 5: 22, 10: 25, 25: 31, 50: 35, 100: 39, 
    }, 
  },
  {
    scenario: 'RCP2.6',
    term: '2070',
    periods: {
      2: 18, 5: 24, 10: 27, 25: 33, 50: 37, 100: 42, 
    }, 
  },
  {
    scenario: 'RCP2.6',
    term: '2100',
    periods: {
      2: 19, 5: 25, 10: 29, 25: 34, 50: 39, 100: 44, 
    }, 
  },
  {
    scenario: 'RCP4.5',
    term: '2040',
    periods: {
      2: 17, 5: 23, 10: 26, 25: 31, 50: 36, 100: 40, 
    }, 
  },
  {
    scenario: 'RCP4.5',
    term: '2070',
    periods: {
      2: 19, 5: 25, 10: 28, 25: 34, 50: 39, 100: 44, 
    }, 
  },
  {
    scenario: 'RCP4.5',
    term: '2100',
    periods: {
      2: 21, 5: 27, 10: 31, 25: 37, 50: 42, 100: 47, 
    }, 
  },
  {
    scenario: 'RCP8.5',
    term: '2040',
    periods: {
      2: 18, 5: 24, 10: 28, 25: 33, 50: 38, 100: 43, 
    }, 
  },
  {
    scenario: 'RCP8.5',
    term: '2070',
    periods: {
      2: 21, 5: 28, 10: 32, 25: 39, 50: 44, 100: 49, 
    }, 
  },
  {
    scenario: 'RCP8.5',
    term: '2100',
    periods: {
      2: 24, 5: 32, 10: 36, 25: 44, 50: 50, 100: 56, 
    }, 
  },
];

const BGIOptions = [
  { value: null, label: 'No BGI scenario' },
  { value: 'BGIOne', label: 'BGI Scenario 1: Buildings to green roofs' },
  { value: 'BGITwo', label: 'BGI Scenario 2: Grass to BGI' },
  { value: 'BGIThree', label: 'BGI Scenario 3: Unpermeable pavings to BGI' },
]

export {
  rainfallTerm,
  rcpScenario,
  returnPeriod,
  flashFloodData,
  BGIOptions,
}