const defaultState = {
  map: undefined,
  shape: null,
  popup: null,
  exceededRange: false,
  showActiveDrawingLayer: false,
  userSelectedMode: undefined,
  noBuildingIntersecting: false,
};

function maplibreMap(state = defaultState, action) {
  switch (action.type) {
    case 'SET_MAP': {
      return {
        ...state,
        map: action.payload,
      };
    }
    case 'SET_SHAPE': {
      return {
        ...state,
        shape: action.payload,
      };
    }
    case 'SET_SHOW_ACTIVE_DRAWING_LAYER': {
      return {
        ...state,
        showActiveDrawingLayer: action.payload,
      };
    }
    case 'SET_INTERSECTION_STATUS': {
      return {
        ...state,
        noBuildingIntersecting: action.payload,
      }
    }
    case 'SET_USER_SELECTED_MODE': {
      return {
        ...state,
        userSelectedMode: action.payload,
      }
    }
    case 'SET_POPUP': {
      if (state.popup && state.popup.remove && !action.payload) {
        state.popup.remove();
      }

      return {
        ...state,
        popup: action.payload,
      };
    }
    case 'SET_DRAWING_STATUS': {
      return {
        ...state,
        exceededRange: action.payload,
      };
    }
    default:
      return state;
  }
}

export default maplibreMap;