import { Button } from '@nazka/nazka.mapframe.components'

import './mode-selection-modal.styl'
import { useDispatch } from 'react-redux'
import { setUserSelectedMode } from '../../../library/MaplibreMap/maplibre-map.redux.actions'

function ModeSelectionModal() {
  const dispatch = useDispatch()
  
  return (
    <div className="mode-selection-modal">
      <div className="mode-selection-modal__single-card">
        <h4>River flood damage estimation</h4>
        <img src="/img/riverflood.png" alt="flood damage" />
        <p>River flood damage estimation is....</p>
        <Button
          css={{
            borderRadius: '3px',
          }}
          onClick={() => dispatch(setUserSelectedMode('River flood'))}
        >
          Select
        </Button>
      </div>
      <div className="mode-selection-modal__single-card">
        <h4>Flash flood hotspot simulation</h4>
        <img src="/img/logo/eu_flag.jpg" alt="flood damage" />
        <p>Flash flood hotspot simulation....</p>
        <Button
          css={{
            borderRadius: '3px',
          }}
          onClick={() => dispatch(setUserSelectedMode('Flash flood'))}
        >
          Select
        </Button>
      </div>
    </div>
  )
}

export default ModeSelectionModal