/* eslint-disable max-lines */
const columns = [
  {
    field: 'classification',
    headerName: 'Urban atlas land cover and damage classes',
    minwidth: 350,
    flex: 0.6,
    editable: false,
    sortable: false, 
    disableColumnMenu: true,
  },
  {
    field: 'buildingConstruction',
    headerName: '* K(H) Building construction',
    type: 'number',
    align: 'right',
    headerAlign: 'center',
    editable: true,
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'equipment',
    headerName: '* O(H) Equipment, lost profit',
    type: 'number',
    align: 'right',
    headerAlign: 'center',
    editable: true,
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'vehicles',
    headerName: 'Vehicles',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'humanHealth',
    headerName: 'Human health',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'cleaning',
    headerName: 'Cleaning costs',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'farmlandAndForest',
    headerName: 'Farmland and forest',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'environment',
    headerName: 'Environment',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'infrastructure',
    headerName: 'Infrastructure',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'damageTo',
    headerName: 'Damage to water bodies',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
];

const defaultRows = [
  {
    id: '11100',
    classification: 'Continuous urban fabric (S.L. > 80%)',
    buildingConstruction: 380,
    equipment: 426,
    vehicles: 21,
    humanHealth: 8,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11210',
    classification: 'Discontinuous dense urban fabric (S.L. 50% - 80%)',
    buildingConstruction: 216,
    equipment: 249,
    vehicles: 8,
    humanHealth: 3,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11220',
    classification: 'Discontinuous medium density urban fabric (S.L. 30% - 50%)',
    buildingConstruction: 150,
    equipment: 177,
    vehicles: 6,
    humanHealth: 2,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11230',
    classification: 'Discontinuous low density urban fabric (S.L. 10% - 30%)',
    buildingConstruction: 103,
    equipment: 121,
    vehicles: 4,
    humanHealth: 2,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11240',
    classification: 'Discontinuous very low density urban fabric (S.L. < 10%)',
    buildingConstruction: 75,
    equipment: 80,
    vehicles: 2,
    humanHealth: 1,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11300',
    classification: 'Isolated structures',
    buildingConstruction: 94,
    equipment: 121,
    vehicles: 5,
    humanHealth: 2,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '12100',
    classification: 'Industrial, commercial, public, military and private units',
    buildingConstruction: 141,
    equipment: 241,
    vehicles: 12,
    humanHealth: 5,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '12210',
    classification: 'Fast transit roads and associated land',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 7.4,
    damageTo: 0,
  },
  {
    id: '12220',
    classification: 'Other roads and associated land',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 5.2,
    damageTo: 0,
  },
  {
    id: '12230',
    classification: 'Railways and associated land',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 7.4,
    damageTo: 0,
  },
  {
    id: '12300',
    classification: 'Port areas',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 7.4,
    damageTo: 0,
  },
  {
    id: '12400',
    classification: 'Airports',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 7.4,
    damageTo: 0,
  },
  {
    id: '13100',
    classification: 'Mineral extraction and dump sites',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '13300',
    classification: 'Construction sites',
    buildingConstruction: 75,
    equipment: 105,
    vehicles: 3,
    humanHealth: 1,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '13400',
    classification: 'Land without current use',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '14100',
    classification: 'Green urban areas',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 2.52,
    farmlandAndForest: 0,
    environment: 0.12,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '14200',
    classification: 'Sports and leisure facilities',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 2.52,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '21000',
    classification: 'Arable land (annual crops)',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.15,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '22000',
    classification: 'Permanent corps',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.15,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '23000',
    classification: 'Pastures',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.05,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '24000',
    classification: 'Complex and mixed cultivation patterns',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.15,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '31000',
    classification: 'Forests',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.02,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '32000',
    classification: 'Herbaceous vegetation associations',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.05,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '33000',
    classification: 'Open spaces with little or no vegetation',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '40000',
    classification: 'Wetlands',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '50000',
    classification: 'Water',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 0,
    damageTo: 6,
  },
];

const infiltrationColumn = [
  {
    field: 'classification',
    headerName: 'Urban atlas land cover and damage classes',
    flex: 0.1,
    editable: false,
    sortable: false, 
    disableColumnMenu: true,
  },
  {
    field: 'defaultCN',
    headerName: 'CN value',
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    editable: true,
    sortable: false, 
    disableColumnMenu: true,
  },
]

const defaultInfiltrationParameters = [
  {
    id: '11100',
    classification: 'Continuous urban fabric (S.L. > 80%)',
    defaultCN: 94,
    CN: {
      A: 89,
      BGIOneA: 84.95,
      BGITwoA: 90.80,
      BGIThreeA: 71.54,
      B: 92,
      BGIOneB: 88.25,
      BGITwoB: 90.80,
      BGIThreeB: 69.59,
      C: 94,
      BGIOneC: 90.2,
      BGITwoC: 90.8,
      BGIThreeC: 71.54,
      D: 95,
      BGIOneD: 91.1,
      BGITwoD: 90.80,
      BGIThreeD: 72.44,
    },
  },
  {
    id: '11210',
    classification: 'Discontinuous dense urban fabric (S.L. 50% - 80%)',
    defaultCN: 90,
    CN: {
      A: 77,
      BGIOneA: 73.99,
      BGITwoA: 81.21,
      BGIThreeA: 72.68,
      B: 85,
      BGIOneB: 81.69,
      BGITwoB: 81.21,
      BGIThreeB: 68.13,
      C: 90,
      BGIOneC: 86.24,
      BGITwoC: 81.21,
      BGIThreeC: 72.68,
      D: 91,
      BGIOneD: 88.34,
      BGITwoD: 81.21,
      BGIThreeD: 74.78,
    },
  },
  {
    id: '11220',
    classification: 'Discontinuous medium density urban fabric (S.L. 30% - 50%)',
    defaultCN: 83,
    CN: {
      A: 61,
      BGIOneA: 58.78,
      BGITwoA: 68.25,
      BGIThreeA: 75.80,
      B: 75,
      BGIOneB: 72.42,
      BGITwoB: 68.25,
      BGIThreeB: 67.74,
      C: 83,
      BGIOneC: 80.48,
      BGITwoC: 68.25,
      BGIThreeC: 75.80,
      D: 87,
      BGIOneD: 84.2,
      BGITwoD: 68.25,
      BGIThreeD: 79.52,
    },
  },
  {
    id: '11230',
    classification: 'Discontinuous low density urban fabric (S.L. 10% - 30%)',
    defaultCN: 79,
    CN: {
      A: 51,
      BGIOneA: 49,
      BGITwoA: 59.62,
      BGIThreeA: 76.51,
      B: 68,
      BGIOneB: 66.6,
      BGITwoB: 59.62,
      BGIThreeB: 66.11,
      C: 79,
      BGIOneC: 77,
      BGITwoC: 59.62,
      BGIThreeC: 76.51,
      D: 84,
      BGIOneD: 81.8,
      BGITwoD: 59.62,
      BGIThreeD: 81.31,
    },
  },
  {
    id: '11240',
    classification: 'Discontinuous very low density urban fabric (S.L. < 10%)',
    defaultCN: 77,
    CN: {
      A: 46,
      BGIOneA: 45.24,
      BGITwoA: 55.78,
      BGIThreeA: 74.59,
      B: 65,
      BGIOneB: 64.6,
      BGITwoB: 55.78,
      BGIThreeB: 63.15,
      C: 77,
      BGIOneC: 76.04,
      BGITwoC: 55.78,
      BGIThreeC: 74.59,
      D: 82,
      BGIOneD: 81.32,
      BGITwoD: 55.78,
      BGIThreeD: 79.87,
    },
  },
  {
    id: '11300',
    classification: 'Isolated structures',
    defaultCN: 76,
    CN: {
      A: 45,
      BGIOneA: 40.12,
      BGITwoA: 50.98,
      BGIThreeA: 73.79,
      B: 64,
      BGIOneB: 61.68,
      BGITwoB: 50.98,
      BGIThreeB: 61.05,
      C: 76,
      BGIOneC: 74.42,
      BGITwoC: 50.98,
      BGIThreeC: 73.79,
      D: 81,
      BGIOneD: 80.3,
      BGITwoD: 50.98,
      BGIThreeD: 79.67,
    },
  },
  {
    id: '12100',
    classification: 'Industrial, commercial, public, military and private units',
    defaultCN: 91,
    CN: {
      A: 81,
      BGIOneA: 77.88,
      BGITwoA: 84.57,
      BGIThreeA: 72.07,
      B: 88,
      BGIOneB: 84.04,
      BGITwoB: 84.57,
      BGIThreeB: 68.43,
      C: 91,
      BGIOneC: 87.68,
      BGITwoC: 84.57,
      BGIThreeC: 72.07,
      D: 93,
      BGIOneD: 89.36,
      BGITwoD: 84.57,
      BGIThreeD: 73.75,
    },
  },
  {
    id: '12210',
    classification: 'Fast transit roads and associated land',
    defaultCN: 98,
    CN: {
      A: 98,
      BGIOneA: 98,
      BGITwoA: 98,
      BGIThreeA: 98,
      B: 98,
      BGIOneB: 98,
      BGITwoB: 98,
      BGIThreeB: 98,
      C: 98,
      BGIOneC: 98,
      BGITwoC: 98,
      BGIThreeC: 98,
      D: 98,
      BGIOneD: 98,
      BGITwoD: 98,
      BGIThreeD: 98,
    },
  },
  {
    id: '12220',
    classification: 'Other roads and associated land',
    defaultCN: 98,
    CN: {
      A: 98,
      BGIOneA: 98,
      BGITwoA: 98,
      BGIThreeA: 98,
      B: 92,
      BGIOneB: 98,
      BGITwoB: 98,
      BGIThreeB: 98,
      C: 98,
      BGIOneC: 98,
      BGITwoC: 98,
      BGIThreeC: 98,
      D: 98,
      BGIOneD: 98,
      BGITwoD: 98,
      BGIThreeD: 98,
    },
  },
  {
    id: '12230',
    classification: 'Railways and associated land',
    defaultCN: 98,
    CN: {
      A: 98,
      BGIOneA: 98,
      BGITwoA: 98,
      BGIThreeA: 98,
      B: 98,
      BGIOneB: 98,
      BGITwoB: 98,
      BGIThreeB: 98,
      C: 98,
      BGIOneC: 98,
      BGITwoC: 98,
      BGIThreeC: 98,
      D: 98,
      BGIOneD: 98,
      BGITwoD: 98,
      BGIThreeD: 98,
    },
  },
  {
    id: '12300',
    classification: 'Port areas',
    defaultCN: 94,
    CN: {
      A: 89,
      BGIOneA: 89,
      BGITwoA: 89,
      BGIThreeA: 89,
      B: 92,
      BGIOneB: 92,
      BGITwoB: 92,
      BGIThreeB: 92,
      C: 94,
      BGIOneC: 94,
      BGITwoC: 94,
      BGIThreeC: 94,
      D: 95,
      BGIOneD: 95,
      BGITwoD: 95,
      BGIThreeD: 95,
    },
  },
  {
    id: '12400',
    classification: 'Airports',
    defaultCN: 94,
    CN: {
      A: 89,
      BGIOneA: 89,
      BGITwoA: 89,
      BGIThreeA: 89,
      B: 92,
      BGIOneB: 92,
      BGITwoB: 92,
      BGIThreeB: 92,
      C: 94,
      BGIOneC: 94,
      BGITwoC: 94,
      BGIThreeC: 94,
      D: 95,
      BGIOneD: 95,
      BGITwoD: 95,
      BGIThreeD: 95,
    },
  },
  {
    id: '13100',
    classification: 'Mineral extraction and dump sites',
    defaultCN: 91,
    CN: {
      A: 77,
      BGIOneA: 77,
      BGITwoA: 77,
      BGIThreeA: 77,
      B: 86,
      BGIOneB: 86,
      BGITwoB: 86,
      BGIThreeB: 86,
      C: 91,
      BGIOneC: 91,
      BGITwoC: 91,
      BGIThreeC: 91,
      D: 94,
      BGIOneD: 94,
      BGITwoD: 94,
      BGIThreeD: 94,
    },
  },
  {
    id: '13300',
    classification: 'Construction sites',
    defaultCN: 91,
    CN: {
      A: 77,
      BGIOneA: 77,
      BGITwoA: 77,
      BGIThreeA: 77,
      B: 86,
      BGIOneB: 86,
      BGITwoB: 86,
      BGIThreeB: 86,
      C: 91,
      BGIOneC: 91,
      BGITwoC: 91,
      BGIThreeC: 91,
      D: 94,
      BGIOneD: 94,
      BGITwoD: 94,
      BGIThreeD: 94,
    },
  },
  {
    id: '13400',
    classification: 'Land without current use',
    defaultCN: 79,
    CN: {
      A: 49,
      BGIOneA: 49,
      BGITwoA: 50.02,
      BGIThreeA: 52.27,
      B: 69,
      BGIOneB: 69,
      BGITwoB: 50.02,
      BGIThreeB: 52.27,
      C: 79,
      BGIOneC: 79,
      BGITwoC: 50.02,
      BGIThreeC: 52.27,
      D: 84,
      BGIOneD: 84,
      BGITwoD: 50.02,
      BGIThreeD: 52.27,
    },
  },
  {
    id: '14100',
    classification: 'Green urban areas',
    defaultCN: 41,
    CN: {
      A: 24,
      BGIOneA: 24,
      BGITwoA: 50.02,
      BGIThreeA: 24,
      B: 35,
      BGIOneB: 35,
      BGITwoB: 50.02,
      BGIThreeB: 35,
      C: 41,
      BGIOneC: 41,
      BGITwoC: 50.02,
      BGIThreeC: 41,
      D: 43,
      BGIOneD: 43,
      BGITwoD: 50.02,
      BGIThreeD: 43,
    },
  },
  {
    id: '14200',
    classification: 'Sports and leisure facilities',
    defaultCN: 79,
    CN: {
      A: 49,
      BGIOneA: 48.14,
      BGITwoA: 58.64,
      BGIThreeA: 75.53,
      B: 69,
      BGIOneB: 65.74,
      BGITwoB: 58.64,
      BGIThreeB: 65.13,
      C: 79,
      BGIOneC: 76.14,
      BGITwoC: 58.64,
      BGIThreeC: 75.53,
      D: 84,
      BGIOneD: 80.94,
      BGITwoD: 58.64,
      BGIThreeD: 80.33,
    },
  },
  {
    id: '21000',
    classification: 'Arable land (annual crops)',
    defaultCN: 65,
    CN: {
      A: 51,
      BGIOneA: 51,
      BGITwoA: 51,
      BGIThreeA: 51,
      B: 60,
      BGIOneB: 60,
      BGITwoB: 60,
      BGIThreeB: 60,
      C: 65,
      BGIOneC: 65,
      BGITwoC: 65,
      BGIThreeC: 65,
      D: 68,
      BGIOneD: 68,
      BGITwoD: 68,
      BGIThreeD: 68,
    },
  },
  {
    id: '22000',
    classification: 'Permanent corps',
    defaultCN: 82,
    CN: {
      A: 64,
      BGIOneA: 64,
      BGITwoA: 64,
      BGIThreeA: 64,
      B: 75,
      BGIOneB: 75,
      BGITwoB: 75,
      BGIThreeB: 75,
      C: 82,
      BGIOneC: 82,
      BGITwoC: 82,
      BGIThreeC: 82,
      D: 85,
      BGIOneD: 85,
      BGITwoD: 85,
      BGIThreeD: 85,
    },
  },
  {
    id: '23000',
    classification: 'Pastures',
    defaultCN: 74,
    CN: {
      A: 39,
      BGIOneA: 39,
      BGITwoA: 39,
      BGIThreeA: 39,
      B: 61,
      BGIOneB: 61,
      BGITwoB: 61,
      BGIThreeB: 61,
      C: 74,
      BGIOneC: 74,
      BGITwoC: 74,
      BGIThreeC: 74,
      D: 80,
      BGIOneD: 80,
      BGITwoD: 80,
      BGIThreeD: 80,
    },
  },
  {
    id: '24000',
    classification: 'Complex and mixed cultivation patterns',
    defaultCN: 82,
    CN: {
      A: 64,
      BGIOneA: 64,
      BGITwoA: 64,
      BGIThreeA: 64,
      B: 75,
      BGIOneB: 75,
      BGITwoB: 75,
      BGIThreeB: 75,
      C: 82,
      BGIOneC: 82,
      BGITwoC: 82,
      BGIThreeC: 82,
      D: 85,
      BGIOneD: 85,
      BGITwoD: 85,
      BGIThreeD: 85,
    },
  },
  {
    id: '31000',
    classification: 'Forests',
    defaultCN: 70,
    CN: {
      A: 30,
      BGIOneA: 30,
      BGITwoA: 30,
      BGIThreeA: 30,
      B: 55,
      BGIOneB: 55,
      BGITwoB: 55,
      BGIThreeB: 55,
      C: 70,
      BGIOneC: 70,
      BGITwoC: 70,
      BGIThreeC: 70,
      D: 77,
      BGIOneD: 77,
      BGITwoD: 77,
      BGIThreeD: 77,
    },
  },
  {
    id: '32000',
    classification: 'Herbaceous vegetation associations',
    defaultCN: 72,
    CN: {
      A: 35,
      BGIOneA: 35,
      BGITwoA: 35,
      BGIThreeA: 35,
      B: 58,
      BGIOneB: 58,
      BGITwoB: 58,
      BGIThreeB: 58,
      C: 72,
      BGIOneC: 72,
      BGITwoC: 72,
      BGIThreeC: 72,
      D: 79,
      BGIOneD: 79,
      BGITwoD: 79,
      BGIThreeD: 79,
    },
  },
  {
    id: '33000',
    classification: 'Open spaces with little or no vegetation',
    defaultCN: 91,
    CN: {
      A: 77,
      BGIOneA: 77,
      BGITwoA: 50.02,
      BGIThreeA: 52.27,
      B: 86,
      BGIOneB: 86,
      BGITwoB: 50.02,
      BGIThreeB: 52.27,
      C: 91,
      BGIOneC: 91,
      BGITwoC: 50.02,
      BGIThreeC: 52.27,
      D: 94,
      BGIOneD: 91.1,
      BGITwoD: 50.02,
      BGIThreeD: 52.27,
    },
  },
  {
    id: '40000',
    classification: 'Wetlands',
    defaultCN: 0,
    CN: {
      A: 0,
      BGIOneA: 0,
      BGITwoA: 0,
      BGIThreeA: 0,
      B: 0,
      BGIOneB: 0,
      BGITwoB: 0,
      BGIThreeB: 0,
      C: 0,
      BGIOneC: 0,
      BGITwoC: 0,
      BGIThreeC: 0,
      D: 0,
      BGIOneD: 0,
      BGITwoD: 0,
      BGIThreeD: 0,
    },
  },
  {
    id: '50000',
    classification: 'Water',
    defaultCN: 0,
    CN: {
      A: 0,
      BGIOneA: 0,
      BGITwoA: 0,
      BGIThreeA: 0,
      B: 0,
      BGIOneB: 0,
      BGITwoB: 0,
      BGIThreeB: 0,
      C: 0,
      BGIOneC: 0,
      BGITwoC: 0,
      BGIThreeC: 0,
      D: 0,
      BGIOneD: 0,
      BGITwoD: 0,
      BGIThreeD: 0,
    },
  },
]

export {
  columns,
  defaultRows,
  defaultInfiltrationParameters,
  infiltrationColumn,
}