function setIsCreatingNewDamageLayer(bool) {
  return {
    type: 'SET_IS_CREATING_NEW_DAMAGE_LAYER',
    payload: bool,
  };
}

function setRainfallScenario(value) {
  return {
    type: 'SET_RAINFALL_SCENARIO',
    payload: value,
  }
}

function setIsCreatingNewArea(bool) {
  return {
    type: 'SET_IS_CREATING_NEW_AREA',
    payload: bool,
  };
}

function setSelectedAreaId(payload) {
  return {
    type: 'SET_NEW_DAMAGE_LAYER_SELECTED_AREA_ID',
    payload,
  };
} 

export {
  setIsCreatingNewDamageLayer,
  setIsCreatingNewArea,
  setSelectedAreaId,
  setRainfallScenario,
};