import React from 'react'
import { useSelector } from 'react-redux';
import DamageParameter from '@App/components/DamageParameter/DamageParameter'
import LayerSelector from '@App/components/LayerSelector/LayerSelector'
import LocationSearch from '@App/components/LocationSearch/LocationSearch';
import { useAuth } from '@App/components/AuthProvider/auth-provider.context';

import './map-controls-wrapper.styl'

function MapControlsWrapper() {
  const { isLoggedIn } = useAuth();

  const isCreatingNewDamageLayer = useSelector(state => state.newDamageLayer.isCreatingNewDamageLayer);
  const userSelectedMode = useSelector(state => state.maplibreMap.userSelectedMode);
  
  return (
    <div className="map-controls-wrapper">
      <LocationSearch />

      {isLoggedIn && (
        <div className="map-controls-wrapper__layer-damage">
          {!isCreatingNewDamageLayer && <DamageParameter />}
          {(!isCreatingNewDamageLayer || userSelectedMode === 'Flash flood') && <LayerSelector />}
        </div>
      )}
    </div>
  )
}

export default MapControlsWrapper