const defaultState = {
  isCreatingNewDamageLayer: false,
  isCreatingNewArea: false,
  selectedAreaId: null,
  rainfallScenario: 16,
};

function newDamageLayerPanel(state = defaultState, action) {
  switch (action.type) {
    case 'SET_IS_CREATING_NEW_DAMAGE_LAYER':
      return {
        ...state,
        isCreatingNewDamageLayer: action.payload,
      };
    case 'SET_IS_CREATING_NEW_AREA':
      return {
        ...state,
        isCreatingNewArea: action.payload,
      };
    case 'SET_RAINFALL_SCENARIO':
      return {
        ...state,
        rainfallScenario: action.payload,
      }
    case 'SET_NEW_DAMAGE_LAYER_SELECTED_AREA_ID': {
      return {
        ...state,
        selectedAreaId: action.payload,
      };
    }
    default:
      return state;
  }
}

export default newDamageLayerPanel;