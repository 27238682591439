/* eslint-disable max-lines */
import { useState } from 'react'
import { Button, ScrollContainer, Icon } from '@nazka/nazka.mapframe.components'
import {
  faXmark, faSliders, faPlus, faTrash,
  faPencil, 
} from '@fortawesome/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import axios from 'axios'
import queryClient from '@utils/queries/QueryClient';

import { useUserDamageParameters, useUserInfiltrationParameters } from '@utils/queries/user.queries'
import { useSelector, useDispatch } from 'react-redux'
import DamageParameterTable from './DamageParameterTable/DamageParameterTable'
import {
  setDamageParameterExpandedStatus, setShowDamagePrameterTable, setSpecificDamageParameterId,
} from './damage-parameter.redux.actions'

import './damage-parameter.styl'

function DamageParameter() {
  const isExpanded = useSelector(state => state.damageParameter.isDamageParameterExpanded)
  const [deleteIsTriggered, setDeleteIsTriggered] = useState(null)
  const dispatch = useDispatch()
  const showDamagePrameterTable = useSelector(state => state.damageParameter.showDamagePrameterTable)
  const userSelectedMode = useSelector(state => state.maplibreMap.userSelectedMode);

  const { data: userDamageParameters } = useUserDamageParameters()
  const { data: userInfiltrationParameters } = useUserInfiltrationParameters()
  
  const allParameter = userSelectedMode === 'River flood' ? userDamageParameters : userInfiltrationParameters
  const endpointURL = userSelectedMode === 'River flood' ? '/api/damage-parameters/' : '/api/infiltration-parameters/'

  const { mutate: removeDamageParameter } = useMutation({
    mutationFn: async (damageParameterId) => {
      await axios.delete(`${endpointURL}${damageParameterId}`);
    },
    onSettled: () => {
      const queryKey = userSelectedMode === 'River flood' ? 'damage-parameters' : 'infiltration-parameters'
      queryClient.invalidateQueries({ queryKey: ['user', queryKey] })
    },
    onSuccess: () => {
      toast.success('Successfully deleted your damage parameter')
      setDeleteIsTriggered(false)
    },
    onError: error => toast.error(error.message || 'Something went wrong deleting your damage parameter'),
  });

  return (
    <div className="damage-paremeter">
      {isExpanded && (
        <ScrollContainer
          maxHeight={114}
          css={{
            width: '275px',
          }}
        >
          <div className="damage-parameter__card">
            <div className="damage-parameter__card-header">
              <Icon icon={faSliders} size="18px" />
              <span>{userSelectedMode === 'River flood' ? 'Damage parameters' : 'Infiltration parameters'}</span>
              <Button
                className="damage-parameter__card__add__button"
                icon={faPlus}
                layout="ghost"
                iconSize="15px"
                color="grey"
                hover={false}
                onClick={() => dispatch(setShowDamagePrameterTable(true))}
                css={{
                  padding: 0,
                }}
              />
            </div>
            <hr style={{
              opacity: 0.1, margin: '8px 10px 0 10px',
            }}
            />
            <div className="damage-parameter__saved-params__wrapper">
              {allParameter?.length > 0 ? (
                <div style={{ paddingBottom: '6px' }}>
                  {allParameter.map((damage) => {
                    return (
                      <div className="damage-parameter__saved-params">
                        <span>{damage.parameterName}</span>
                        {damage.id === deleteIsTriggered ? (
                          <div className="damage-parameter__confirmation__wrapper">
                            <Button
                              color="#0367A5"
                              hoverColor="#0367A5"
                              css={{
                                borderRadius: '3px',
                                padding: '3px',
                                fontSize: '12px',
                                marginBottom: '6px',
                              }}
                              onClick={() => removeDamageParameter(damage.id)}
                            >
                              Confirm
                            </Button>
                            <Button
                              color="#0367A5"
                              hoverColor="#0367A5"
                              onClick={() => setDeleteIsTriggered(null)}
                              layout="outlined"
                              css={{
                                borderRadius: '3px',
                                padding: '3px',
                                fontSize: '12px',
                                marginBottom: '6px',
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <div className="damage-parameter__saved-params__button__wrapper">
                            <Button 
                              icon={faPencil} 
                              onClick={() => {
                                dispatch(setSpecificDamageParameterId(damage.id))
                                dispatch(setShowDamagePrameterTable(true))
                              }} 
                              color="white"
                              hover={false}
                              className="damage-parameter__edit__button"
                              iconProps={{
                                size: '14px',
                              }}
                              css={{
                                color: 'grey',
                                marginLeft: 0,
                                padding: 0,
                                '&:hover': {
                                  color: 'black',
                                },
                                '&:disabled': {
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  color: 'grey',
                                  '&:hover': {
                                    color: 'grey',
                                  },
                                },
                              }}
                            />
                            <Button 
                              icon={faTrash} 
                              onClick={() => setDeleteIsTriggered(damage.id)} 
                              color="white"
                              hover={false}
                              className="damage-parameter__delete__button"
                              iconProps={{
                                size: '14px',
                              }}
                              css={{
                                color: 'grey',
                                marginLeft: 0,
                                padding: 0,
                                '&:hover': {
                                  color: 'black',
                                },
                                '&:disabled': {
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  color: 'grey',
                                  '&:hover': {
                                    color: 'grey',
                                  },
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
                
              ) : (
                <span style={{
                  fontSize: '13px', fontStyle: 'italic', display: 'flex', justifyContent: 'center',
                }}
                >
                  {userSelectedMode === 'River flood'
                    ? 'Click on + to create a damage parameter'
                    : 'Click on + to create an infiltration parameter'}
                </span>
              )}
            </div>
          
          </div>
        </ScrollContainer>
      )}
      <div>
        <Button
          icon={isExpanded ? faXmark : faSliders}
          color="white"
          css={{
            borderRadius: '3px',
          }}
          onClick={() => dispatch(setDamageParameterExpandedStatus(!isExpanded))}
        />
      </div>
      {showDamagePrameterTable && (
        <DamageParameterTable />
      )}
     
    </div>
  )
}

export default DamageParameter