import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useSpecificArea } from '@utils/queries/area.queries';

function UserDamageArea({
  areaId,
  map,
}) {
  const { data: area } = useSpecificArea(areaId)
  const userSelectedMode = useSelector(state => state.maplibreMap.userSelectedMode);
  const selectedValue = useSelector(state => state.layerSelector.currentSelectedDamageLayer)

  const areaBorderColor = () => {
    if (userSelectedMode === 'River flood' || !selectedValue) return '#0367A5'
    if (selectedValue.areaPEValue < 20) return '#ffd700'
    if (selectedValue.areaPEValue < 50) return '#E5A300'
    if (selectedValue.areaPEValue < 100) return '#E86A0A'
    return '#cf0d14'
  }
  
  useEffect(() => {
    if (area) {
      if (!map.getSource('user-area')) {
        map.addSource('user-area', {
          type: 'geojson',
          data: area.feature,
        });
      } else {
        map.getSource('user-area').setData(area.feature);
      }
    }
  }, [map, area]);

  useEffect(() => {
    if (map.getSource('user-area')) {
      map.addLayer({
        id: 'user-area-outer',
        type: 'line',
        source: 'user-area',
        paint: {
          'line-color': areaBorderColor(),
          'line-width': 2,
          'line-dasharray': [2, 2],
        },
      }, map.getLayer('z-index-5') ? 'z-index-5' : '');
    }

    return () => {
      if (map && !map._removed) {
        if (map.getLayer('user-area-outer')) {
          map.removeLayer('user-area-outer');
        }
  
        if (map.getSource('user-area')) {
          map.removeSource('user-area');
        }
      }
    };
  }, [map, area]);

  return null;
}

export default UserDamageArea;