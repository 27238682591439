import axios from 'axios';
import store from '@App/app.store';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '@App/components/AuthProvider/auth-provider.context';

function useFloodDamages() {
  const { isLoggedIn } = useAuth();
  const { userSelectedMode } = store.getState().maplibreMap

  return useQuery({
    queryKey: ['user', 'flood-damages'],
    queryFn: async () => {
      const url = userSelectedMode === 'River flood' ? '/api/calculation/damage' : '/api/calculation/flashflooddamage'
      const { data } = await axios.get(url);
      return data;
    },
    enabled: isLoggedIn,
  });
}

function useSpecificFloodDamages(areaId) {
  const { isLoggedIn } = useAuth();
  
  return useQuery({
    queryKey: ['user', 'flood-damages', areaId],
    queryFn: async () => {
      const { data } = await axios.get(`/api/calculation/damage?areaId=${areaId}`);
      return data;
    },
    enabled: areaId && isLoggedIn,
  });
}

async function deleteFloodDamages() {
  await axios.delete('/api/calculation/damage')
}

export {
  useFloodDamages,
  deleteFloodDamages,
  useSpecificFloodDamages,
};