import store from '@App/app.store';
import { setRangeExceeded } from '../../../../maplibre-map.redux.actions';

function getShapeType(shape) {
  if (shape === 'polyline') return 'line';
  if (shape === 'circle') return 'fill';
  return 'fill';
}

const getColor = (area, map, feature, options) => {
  const shapeId = `measurement-control-${feature.properties.shape}`;
  const defaultFillColor = options.fillColor; 
  // const { userSelectedMode } = store.getState().maplibreMap
  
  // const upperLayerFeatures = map.queryRenderedFeatures({ layers: ['buildings'] });
  // const isFeatureIntersecting = upperLayerFeatures.some((appearingFeature) => {
  //   return turf.booleanIntersects(appearingFeature, feature)
  // })
  const exceedsRange = area > 100;
  store.dispatch(setRangeExceeded(exceedsRange));

  // const isBuildingIntersecting = (userSelectedMode === 'Flash flood' && isFeatureIntersecting === false)
  // store.dispatch(setBuildingsIntersecting(isBuildingIntersecting))

  const fillColor = exceedsRange ? 'rgba(220, 56, 73, 0.579)' : defaultFillColor;
  map.setPaintProperty(shapeId, 'fill-color', fillColor);
};

function addSourcesAndLayers(map, options) {
  map.addSource('measurement-control', {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  });
  map.addSource('measurement-control-labels', {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  });
  map.addSource('measurement-control-usability-dots', {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  });

  const labelLayerBase = {
    source: 'measurement-control-labels',
    type: 'symbol',
    layout: {
      'symbol-placement': 'point',
      'text-font': ['Open Sans Regular'],
      'text-field': ['get', 'label'],
      'text-size': 12,

    },
    paint: {
      'text-halo-blur': 2,
      'text-halo-color': '#fff',
      'text-halo-width': 2,
    },
  }
  
  map.addLayer({
    ...labelLayerBase,
    id: 'measurement-control-point-labels',
    filter: ['==', ['get', 'geometryType'], 'Point'],
    layout: {
      ...labelLayerBase.layout,
      'symbol-placement': 'point',
    },
  }, 'z-index-6');
  map.addLayer({
    ...labelLayerBase,
    id: 'measurement-control-line-labels',
    filter: ['==', ['get', 'geometryType'], 'LineString'],
    layout: {
      ...labelLayerBase.layout,
      'symbol-placement': 'line-center',
    },
  }, 'measurement-control-point-labels');

  const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
  if (options.helperDots !== 'never' && (options.helperDots === 'always' || isTouchDevice)) {
    map.addLayer({
      id: 'measurement-control-usability-dots',
      source: 'measurement-control-usability-dots',
      type: 'circle',
      paint: {
        'circle-radius': 5,
        'circle-color': options.helperDotColor,
      },
    }, 'measurement-control-point-labels');
  }
  
  [...options.shapes].reverse().forEach((shape) => {
    const paint = {};

    if (shape === 'polyline') {
      paint['line-color'] = options.lineColor;
    } else {
      paint['fill-color'] = options.fillColor;
    }
    
    const layer = {
      id: `measurement-control-${shape}`,
      source: 'measurement-control',
      type: getShapeType(shape),
      paint,
    }
    const layer2 = {
      id: `measurement-control-${shape}-outer`,
      source: 'measurement-control',
      type: 'line',
      paint: {
        'line-color': options.lineColor,
        'line-width': 2,
        'line-dasharray': [2, 2],
      },
    }

    if (shape !== 'polyline') layer.filter = ['==', ['get', 'shape'], shape];
    map.addLayer(layer, 'measurement-control-line-labels');
    map.addLayer(layer2, 'measurement-control-line-labels')
  });
}

function removeSourcesAndLayers(map, options) {
  map.removeLayer('measurement-control-point-labels');
  map.removeLayer('measurement-control-line-labels');
  map.removeLayer('measurement-control-poly-labels');
  map.removeSource('measurement-control-labels');

  map.removeLayer('measurement-control-preview');
  map.removeSource('measurement-control-preview');
  
  if (map.getLayer('measurement-control-usability-dots')) {
    map.removeLayer('measurement-control-usability-dots');
  }
  map.removeSource('measurement-control-usability-dots');

  options.shapes.forEach(shape => map.removeLayer(`measurement-control-${shape}`));
  
  map.removeSource('measurement-control');
}

export { addSourcesAndLayers, removeSourcesAndLayers, getColor };