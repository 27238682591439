import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { useUserDamageParameters, useUserInfiltrationParameters } from '@utils/queries/user.queries';
import { Button } from '@nazka/nazka.mapframe.components';
import { setShowDamagePrameterTable } from '../../DamageParameter/damage-parameter.redux.actions';

import './custom-select.styl'

function CustomMenu(props) {
  const dispatch = useDispatch()
  return (
    <components.Menu {...props}>
      <>
        {props.children}
        <div>
          <Button
            type="button"
            layout="ghost"
            hover={false}
            css={{
              fontStyle: 'italic',
            }}
            onClick={() => dispatch(setShowDamagePrameterTable(true))}
          >
            Create new parameter set
          </Button>
        </div>
      </>
    </components.Menu>
  );
}

function CustomSelect({ onChange }) {
  const { data: userDamageParameters = [] } = useUserDamageParameters()
  const { data: userInfiltrationParameters = [] } = useUserInfiltrationParameters()
  const userSelectedMode = useSelector(state => state.maplibreMap.userSelectedMode);
  
  // const selectCustomStyles = {
  //   menuList: provided => ({
  //     ...provided,
  //     maxHeight: '85px',
  //     overflowY: 'auto', 
  //   }),
  // };
  
  const damageParameterOptions = [
    { value: null, label: 'Standard parameter' },
    ...userDamageParameters.map(param => ({
      value: param.id,
      label: param.parameterName, 
    })),
  ]

  const infiltrationParameterOptiona = [
    { value: 'A', label: 'Soil category A (high infiltration)' },
    { value: 'B', label: 'Soil category B (moderate infiltration)' },
    { value: 'C', label: 'Soil category C (low infiltration)' },
    { value: 'D', label: 'Soil category D (very low infiltration)' },
    ...userInfiltrationParameters.map(param => ({
      value: param.id,
      label: param.parameterName, 
    })),
  ]

  return (
    <div>
      <Select
        options={userSelectedMode === 'River flood' ? damageParameterOptions : infiltrationParameterOptiona}
        components={{ Menu: CustomMenu }}
        defaultValue={userSelectedMode === 'River flood' ? damageParameterOptions[0] : infiltrationParameterOptiona[2]}
        className="custom-select"
        // styles={selectCustomStyles}
        onChange={selectedOption => onChange(selectedOption.value)}
      />
    </div>
  )
}

export default CustomSelect