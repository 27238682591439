function setMap(map) {
  return {
    type: 'SET_MAP',
    payload: map,
  };
}

function setPopup(popup) {
  return {
    type: 'SET_POPUP',
    payload: popup,
  };
}

function setActiveShape(shape) {
  return {
    type: 'SET_SHAPE',
    payload: shape,
  }
}

function setShowActiveDrawingLayer(layer) {
  return {
    type: 'SET_SHOW_ACTIVE_DRAWING_LAYER',
    payload: layer,
  }
}

function setUserSelectedMode(mode) {
  return {
    type: 'SET_USER_SELECTED_MODE',
    payload: mode,
  }
}

function setBuildingsIntersecting(status) {
  return {
    type: 'SET_INTERSECTION_STATUS',
    payload: status,
  }
}

function setRangeExceeded(exceededRange) {
  return {
    type: 'SET_DRAWING_STATUS',
    payload: exceededRange,
  }
}

export {
  setMap,
  setActiveShape,
  setPopup,
  setRangeExceeded,
  setUserSelectedMode,
  setBuildingsIntersecting,
  setShowActiveDrawingLayer,
};