import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function FlashFloodDamagesLayer({
  currentSelectedDamageLayer,
  map,
}) {
  const legendSelection = useSelector(state => state.legend.legendSelection);
  const dispatch = useDispatch()
  const { minTotalDamage, maxTotalDamage } = currentSelectedDamageLayer || { minTotalDamage: '', maxTotalDamage: '' }
  
  useEffect(() => {
    if (currentSelectedDamageLayer) {
      if (!map.getSource('building-damages')) {
        map.addSource('building-damages', {
          type: 'geojson',
          data: currentSelectedDamageLayer,
        });
      } else {
        map.getSource('building-damages').setData(currentSelectedDamageLayer);
      }
    }
  }, [currentSelectedDamageLayer, map]);
  
  useEffect(() => {
    if (legendSelection === 'classes' || minTotalDamage !== maxTotalDamage) {
      const paint = legendSelection === 'classes' 
        ? [
          'step',
          ['get', 'peValue'],
          '#ffd700',
          20, '#ffd700',
          50, '#E5A300',
          100, '#E86A0A',
          200, '#cf0d14',
        ] : [
          'interpolate',
          ['linear'],
          ['get', 'peValue'],
          minTotalDamage, '#f7d2d3',
          maxTotalDamage, '#ca161c',
        ]
  
      map.addLayer({
        id: 'building-damages',
        type: 'fill',
        source: 'building-damages',
        paint: {
          'fill-color': paint,
          'fill-outline-color': '#777',
        },
      }, 'z-index-4');
    }
  
    return () => {
      if (map && !map._removed) {
        if (map.getLayer('building-damages')) {
          map.removeLayer('building-damages');
        }
    
        if (map.getSource('building-damages')) {
          map.getSource('building-damages');
        }
      }
    };
  }, [map, legendSelection, dispatch, minTotalDamage, maxTotalDamage]);

  return null
}

export default FlashFloodDamagesLayer