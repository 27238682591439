const setShowDamagePrameterTable = (status) => {
  return {
    type: 'SET_SHOW_DAMAGE_PARAMETER_TABLE',
    payload: status,
  }
}

const setDamageParameterExpandedStatus = (status) => { 
  return {
    type: 'SET_DAMAGE_PARAMETER_EXPANDED_STATUS',
    payload: status,
  }
}

const setSpecificDamageParameterId = (id) => {
  return {
    type: 'SET_SPECIFIC_DAMAGE_PARAMETER_ID',
    payload: id,
  }
}

export {
  setShowDamagePrameterTable,
  setDamageParameterExpandedStatus,
  setSpecificDamageParameterId,
}